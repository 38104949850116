import React from 'react'
import { useFirestoreDocData } from 'reactfire';
import { doc } from '@firebase/firestore'

import withFirestore from './withFirestore'

const FirestoreDataComp = ({ FsdSubcomp, firestorePath = '', ...props }) => {
  const firestoreRef = props.firestore && (firestorePath ? doc(props.firestore, ...firestorePath.split('/')) : props.firestoreRef);
  const firestoreData = useFirestoreDocData(firestoreRef);
  return <FsdSubcomp {...props} firestoreData={firestoreData} firestoreRef={firestoreRef} />
}

const WithFirestoreDataComp = withFirestore(FirestoreDataComp)

const withFirestoreData = (FsdSubcomp) => typeof window === 'undefined'
  ? (props => <FsdSubcomp {...props} firestoreData={{ status: 'loading' }} />)
  : (props => <WithFirestoreDataComp {...props} FsdSubcomp={FsdSubcomp} />
)

export default withFirestoreData
