import React from 'react'
import { Box, VStack } from '@chakra-ui/layout'
import { doc, updateDoc } from '@firebase/firestore'
import { navigate } from 'gatsby-link'
import { IconButton } from '@chakra-ui/button'
import { Controller, useForm, useFormState } from 'react-hook-form'

import DrawArrow from '../../../components/DrawArrow'
import FormField from '../../../components/FormField'
import Text from '../../../components/Text'
import DrawButton from '../../../components/DrawButton'

import withFirestoreData from '../../../utils/withFirestoreData'
import withFirestore from '../../../utils/withFirestore'

const fields = [
  { name: 'inviteeEmail', label: '你的Email', type: 'email' },
  { name: 'email', label: '對方的Email', type: 'email', isReadOnly: true },
]

const AcceptConfirm = ({ onBack, data, state, firestoreRef }) => {
  const onSubmit = async (data) => {
    try {
      await updateDoc(firestoreRef, {
        inviteeEmail: data.inviteeEmail,
        inviteeId: state.firestoreId,
        accepted: true,
      })
      window.localStorage.setItem(`superquiz:love-languages:${state?.invitationId}:accepted`, 1)
      navigate(`/q/love-languages/lover/result/`, { state: { ...state, dualId: state.invitationId } })
    } catch (e) {
      console.error(e)
    }

  }
  const { control, handleSubmit } = useForm({ defaultValues: data })
  const { isSubmitting } = useFormState({ control })

  return (
    <>
      <IconButton onClick={onBack} variant="unstyled" color="love.primary" icon={<DrawArrow />} />
      <Text.LoveP>我們將會將你與{data.name}的雙人分析報告寄至下列信箱！你可以於此修改並確認：</Text.LoveP>
      <VStack as="form" onSubmit={handleSubmit(onSubmit)} width="full" spacing="4">
        {fields.map(f => (
          <Controller
            name={f.name}
            control={control}
            key={f.name}
            render={({ field, fieldState }) => (
              <FormField
                bg="white"
                {...f}
                {...field}
                fieldState={fieldState}
              />
            )}
          />
        ))}
        <Box pt="4" w="full" textAlign="center">
          <DrawButton fontSize="1em" type="submit" isLoading={isSubmitting}>寄出雙人報告並查看個人分析</DrawButton>
        </Box>
      </VStack>
    </>
  )
}


export default AcceptConfirm

const EnhancedAccpt = withFirestoreData(AcceptConfirm)

const HandleFiresoreData = (props) => {
  const invitationId = props.location.state?.invitationId
  const firestoreRef = props.firestore && doc(props.firestore, 'invitations', invitationId);
  return <EnhancedAccpt firestoreRef={firestoreRef} {...props} />
}

AcceptConfirm.Enhanced = withFirestore(HandleFiresoreData)
