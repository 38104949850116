import React, { useState } from 'react'
import { Container } from '@chakra-ui/layout'
import { doc } from '@firebase/firestore'
import { Spinner } from '@chakra-ui/spinner'
import { Helmet } from 'react-helmet'

import Text from '../../../../../components/Text'
import DrawButton from '../../../../../components/DrawButton'
import FullpageVstack from '../../../../../components/FullpageVstack'

// import withAnswers from '../../../../../quizes/love-languages/withAnswers'
import withFirestoreData from '../../../../../utils/withFirestoreData'
import withFirestore from '../../../../../utils/withFirestore'

import AcceptConfirm from '../../../../../quizes/love-languages/result/acceptConfirm'

const Accept = ({ firestoreData: { status, data }, location: { state }, firestoreRef }) => {
  const [accepted, setAccpted] = useState(state?.isLater)
  const isLoading = status === 'loading'
  // console.log(state)

  // const handleReject = async () => {
  //   setSaving(true);
  //   try {
  //     await deleteDoc(firestoreRef)
  //     navigate(`/q/love-languages/lover/calculate/`)
  //   } catch (e) {
  //     console.error(e)
  //     setSaving(false)
  //   }
  // }
  return (
    <Container maxW="container.sm">
      <Helmet>
        <title>同意雙人分析 - 愛之語測驗愛情版</title>
      </Helmet>
      <FullpageVstack alignItems={isLoading ? 'center' : 'flex-start'}>
        {isLoading ? (
          <>
            <Spinner color="love.primary" />
            {!state?.isLater && <Text>愛之語報告產生中...</Text>}
          </>
        ) : (
          accepted ? (
            <AcceptConfirm onBack={() => setAccpted(false)} state={state} data={data} firestoreRef={firestoreRef} />
          ) : (
            <>
              <Text fontSize="1.25em" color="love.primary" fontWeight="bold">在看結果之前...</Text>
              {data && <Text.LoveP>{data.name}想跟你分享他的結果，如果你也願意向對方分享你的結果，你們將同時收到雙人分析報告！</Text.LoveP>}
              <DrawButton onClick={() => setAccpted(true)}>想要收到雙人分析報告</DrawButton>
              <DrawButton to={`/q/love-languages/lover/result/`} state={state}>先看個人分析報告後決定</DrawButton>
              {/* <DrawButton isLoading={saving} onClick={handleReject}>不想要</DrawButton> */}
            </>
          )
        )}
      </FullpageVstack>
    </Container>
  )
}

const EnhancedAccpt = withFirestoreData(Accept)

const HandleFiresoreData = (props) => {
  const invitationId = props.location.state?.invitationId
  const firestoreRef = props.firestore && doc(props.firestore, 'invitations', invitationId);
  return <EnhancedAccpt firestoreRef={firestoreRef} {...props} />
}

export default withFirestore(HandleFiresoreData)
